<template>
  <b-container>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-overlay show spinner-variant="primary" no-wrap />
      </template>
      <h1>{{ $t("properties.deviceProperties") }}</h1>
      <div class="d-flex flex-lg-row flex-column">
        <b-card class="shadow mt-2">
          <h2 class="card-title">
            <i class="fas fa-user-cog mr-2"></i
            >{{ $t("properties.baseProperties") }}
          </h2>
          <div class="property" v-for="prop in baseProperties" :key="prop.name">
            <property-editor
              :label="$t(`properties.names.${prop.name}`)"
              :value="properties[prop.name]"
              :description="
                $te(`properties.descriptions.${prop.name}`)
                  ? $t(`properties.descriptions.${prop.name}`)
                  : ''
              "
              :disabled="!prop.editable"
              @ok="changeProperty(prop.name, $event)"
            />
          </div>
        </b-card>

        <div class="d-flex flex-column ml-lg-2">
          <b-card class="shadow mt-2">
            <h2
              class="
                card-title
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <span
                ><i class="fas fa-cogs mr-2"></i
                >{{ $t("properties.configuration") }}</span
              >
            </h2>
            <div
              class="property"
              v-for="prop in configurationProperties"
              :key="prop.name"
            >
              <subpath-editor
                v-if="prop.name == 'visible_subpaths'"
                :label="$t(`properties.names.${prop.name}`)"
                :value="properties[prop.name]"
                :description="
                  $te(`properties.descriptions.${prop.name}`)
                    ? $t(`properties.descriptions.${prop.name}`)
                    : ''
                "
                @ok="changeProperty(prop.name, $event)"
              />

              <div
                v-else-if="prop.name == 'update_configuration'"
                class="d-flex align-items-center"
              >
                <b-form-group
                  :label="
                    $te(`properties.names.${prop.name}`)
                      ? $t(`properties.names.${prop.name}`)
                      : prop.name
                  "
                  label-for="update_configuration"
                  :invalid-feedback="$t('properties.updateConfigInvalid')"
                  :state="updateConfigState"
                  :description="
                    updateConfigState
                      ? $t(`properties.descriptions.${prop.name}`)
                      : ''
                  "
                  class="w-100"
                >
                  <b-form-select
                    v-model="properties[prop.name]"
                    :options="updateConfigOptions"
                    id="update_configuration"
                    :state="!updateConfigState?false:null"
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="undefined" disabled
                        >--
                        {{ $t("properties.selectOption") }}
                        --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-button
                  variant="primary"
                  @click="changeProperty(prop.name, properties[prop.name])"
                  class="mb-2"
                  :disabled="!updateConfigState"
                >
                  <i class="fas fa-check"></i>
                </b-button>
              </div>

              <property-editor
                v-else
                :label="
                  $te(`properties.names.${prop.name}`)
                    ? $t(`properties.names.${prop.name}`)
                    : prop
                "
                :value="properties[prop.name]"
                :description="
                  $te(`properties.descriptions.${prop.name}`)
                    ? $t(`properties.descriptions.${prop.name}`)
                    : ''
                "
                @ok="changeProperty(prop.name, $event)"
              />
            </div>
          </b-card>
          <b-card class="shadow mt-2" v-if="userProperties">
            <h2
              class="
                card-title
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <span
                ><i class="fas fa-user-cog mr-2"></i
                >{{ $t("properties.user-properties") }}</span
              >
              <b-button v-b-modal.userPropertyInput>{{
                $t("properties.addUserProperty")
              }}</b-button>
            </h2>
            <b-alert
              :show="Object.keys(userProperties).length == 0"
              variant="info"
              >{{ $t("properties.usermenu.NoUserProperties") }}</b-alert
            >
            <property-editor
              v-for="prop in Object.keys(userProperties)"
              :key="prop"
              :label="
                $te(`properties.names.${prop}`)
                  ? $t(`properties.names.${prop}`)
                  : prop
              "
              :value="properties[prop]"
              :description="
                $te(`properties.descriptions.${prop}`)
                  ? $t(`properties.descriptions.${prop}`)
                  : ''
              "
              showDelete
              :showCancel="false"
              @ok="changeProperty(prop, $event)"
              @delete="deleteProperty(prop)"
            />
          </b-card>
        </div>
      </div>
      <b-card class="shadow mt-2">
        <h2
          class="card-title d-flex justify-content-between align-items-center"
        >
          <span
            ><i class="fas fa-terminal mr-2"></i
            >{{ $t("properties.system-properties") }}</span
          >
          <span v-b-toggle.collapse-systemprops
            ><i class="fas fa-chevron-down"></i
          ></span>
        </h2>

        <b-collapse id="collapse-systemprops">
          <property-editor
            v-for="prop in systemProperties"
            :key="prop.name"
            :label="$t(`properties.names.${prop.name}`)"
            :value="properties[prop.name]"
            :description="
              $te(`properties.descriptions.${prop.name}`)
                ? $t(`properties.descriptions.${prop.name}`)
                : ''
            "
            :disabled="!prop.editable"
            @ok="changeProperty(prop.name, $event)"
          />
        </b-collapse>
      </b-card>

      <b-card class="shadow mt-2">
        <h2
          class="card-title d-flex justify-content-between align-items-center"
        >
          <span
            ><i class="fas fa-user-shield mr-2"></i
            >{{ $t("properties.device-actions") }}</span
          >
        </h2>

        <b-form @submit.prevent="deleteDevice">
          <b-form-group :label="$t('device.deleteInformation')">
            <b-form-input
              v-model="idValidation"
              type="text"
              :state="validateId"
              autocomplete="off"
            ></b-form-input>
            <b-form-invalid-feedback :state="validateId">
              <i class="fas fa-exclamation-circle mr-2"></i
              >{{ $t("device.invalidId", { id: this.$route.params.deviceid }) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <p>{{ $t("device.deleteOfflineOnly") }}</p>
          <b-button variant="danger" type="submit" :disabled="!validateId"
            ><i class="fas fa-trash mr-2"></i
            >{{ $t("properties.deleteDevice") }}</b-button
          >
        </b-form>
      </b-card>

      <text-input-modal
        modalId="userPropertyInput"
        :title="$t('properties.addUserProperty')"
        :label="$t('properties.enterTitle')"
        @ok="addUserProperty"
      />
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import PropertyEditor from "@/components/PropertyEditor.vue";
import SubpathEditor from "@/components/SubpathEditor.vue";
import TextInputModal from "@/components/TextInputModal.vue";
import {
  BaseProperties,
  SystemProperties,
  InvisibleProperties,
  ConfigurationProperties,
} from "@/assets/deviceProperties";
import Swal from "sweetalert2";

export default {
  components: { PropertyEditor, SubpathEditor, TextInputModal },
  data() {
    return {
      loading: false,
      properties: {},
      baseProperties: BaseProperties,
      systemProperties: SystemProperties,
      invisibleProperties: InvisibleProperties,
      configurationProperties: ConfigurationProperties,
      userProperties: {},
      idValidation: "",
      updateConfigOptions: [],
    };
  },
  computed: {
    validateId() {
      if (!this.idValidation) return null;
      return this.$route.params.deviceid === this.idValidation;
    },
    updateConfigState() {
      return this.updateConfigOptions.includes(
        this.properties["update_configuration"]
      );
    },
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    loadData() {
      this.loading = true;
      axios
        .get(`/my-devices/api/device/${this.$route.params.deviceid}`)
        .then((res) => {
          this.properties = res.data;

          this.userProperties = Object.keys(this.properties)
            .filter((key) => {
              return (
                !this.baseProperties.map((obj) => obj.name).includes(key) &&
                !this.systemProperties.map((obj) => obj.name).includes(key) &&
                !this.configurationProperties
                  .map((obj) => obj.name)
                  .includes(key) &&
                !this.invisibleProperties.map((obj) => obj.name).includes(key)
              );
            })
            .reduce((obj, key) => {
              return {
                ...obj,
                [key]: this.properties[key],
              };
            }, {});
        })
        .catch((err) => {
          console.error(err);
          this.fireAlert([
            this.$t("Error"),
            this.$t("util.errorLoadingData"),
            "danger",
          ]);
        })
        .finally(() => {
          this.loading = false;

          // add default value for user-menu-entries (just for view)
          if (!Object.keys(this.properties).includes("visible_subpaths")) {
            this.properties["visible_subpaths"] = JSON.stringify([
              {
                title: "energyView",
                path: "/energy-view",
                imagePath: "/bar.svg",
              },
              {
                title: "overview",
                path: "/overview",
                imagePath: "/circular.svg",
              },
              {
                title: "powerView",
                path: "/power-view",
                imagePath: "/tops.svg",
              },
            ]);
          }
        });
    },
    changeProperty(propname, newValue) {
      axios
        .put(`/my-devices/api/device/${this.$route.params.deviceid}`, {
          [propname]: newValue,
        })
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("properties.done"),
            "success",
          ]);
          this.loadData();
        })
        .catch((err) => {
          this.fireAlert([this.$t("Error"), err.response.data, "error"]);
        });
    },
    addUserProperty(name) {
      this.changeProperty(name, "");
    },
    deleteProperty(propname) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger m-1",
          cancelButton: "btn btn-primary m-1",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: this.$t("properties.removeProperty"),
          text: this.$t("properties.removeNote", {
            name: propname,
          }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("user.yesRemoveRole"),
          cancelButtonText: this.$t("user.noCancel"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(
                `/my-devices/api/device/${
                  this.$route.params.deviceid
                }/${encodeURI(propname)}`
              )
              .then(() => {
                this.fireAlert([
                  this.$t("Success"),
                  this.$t("properties.propertyDeleted"),
                  "success",
                ]);
                delete this.userProperties[propname];
                this.$forceUpdate();
              })
              .catch((err) => {
                this.fireAlert([
                  this.$t("Error"),
                  this.$t("properties.propNotDeleted"),
                  "danger",
                ]);
                console.error(err);
              });
          }
        });
    },
    deleteDevice() {
      axios
        .delete(`/my-devices/api/device/${this.$route.params.deviceid}`)
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("device.deviceDeleted"),
            "success",
          ]);
          this.$router.push({ name: "Devices" });
        })
        .catch((err) => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("device.deviceNotDeleted"),
            "danger",
          ]);
          console.error(err);
        });
    },
  },
  mounted() {
    axios
      .get(`/ui-api/v1/device-configs`)
      .then((res) => {
        this.updateConfigOptions = res.data;
      })
      .catch((err) => {
        console.error(err);
        this.updateConfigOptions = [];
      });

    // query device properties
    this.loadData();
  },
};
</script>

<style>
</style>