// {
//     "bytesRx": "34858626",
//     "bytesTx": "2733315",
//     "claimKey": "DTD-Q5H-5HV-UHR-Z5I",
//     "connects": "19",
//     "created": "2021-03-22T12:42:16Z",
//     "createdBy": "admin",
//     "description": "",
//     "domain": "749ef34b-3fc9-4889-92d7-0f8f96fb1d3d",
//     "hashedPassword": "72ee74369e2f552828ca7eaba8d14c9b6e59b9f1",
//     "host": "62.218.82.2",
//     "httpPath": "/",
//     "httpPort": "80",
//     "id": "14a124eb-5a0f-4b19-b550-7f2c874103cd",
//     "lastConnect": "2021-04-15T15:25:55Z",
//     "name": "Martin RPi",
//     "nodered": "show",
//     "online": true,
//     "parent": "",
//     "port": "0",
//     "protocol": "WebTunnel",
//     "server": "127.0.0.1",
//     "sshPort": "22",
//     "tags": "",
//     "targetHost": "172.17.0.1",
//     "targetPorts": "22, 80, 3389, 5900",
//     "type": "",
//     "userAgent": "WebTunnelAgent/1.10.1 (Linux/5.4.51-v7+; armv7l) POCO/1.9.4",
//     "version": "",
//     "vncPort": "3389"
//   }

export const BaseProperties = [
    {
        name: 'name',
        editable: true,
    },
    {
        name: 'description',
        editable: true,
    },
    {
        name: 'tags',
        editable: true,
    },
    {
        name: 'geolocation',
        editable: true,
    },
    {
        name: 'id',
        editable: false,
    },
    {
        name: 'serial',
        editable: true,
    },
    {
        name: 'claimKey',
        editable: true,
    },
]

export const ConfigurationProperties = [
    {
        name: 'max_pv_power',
        editable: true,
    },
    {
        name: 'update_configuration',
        editable: true,
    },
    {
        name: 'visible_subpaths',
        editable: true,
    },
]

export const SystemProperties = [
    {
        name: 'bytesRx',
        editable: false,
    },
    {
        name: 'bytesTx',
        editable: false,
    },
    {
        name: 'connects',
        editable: false,
    },
    {
        name: 'created',
        editable: false,
    },
    {
        name: 'createdBy',
        editable: false,
    },
    {
        name: 'domain',
        editable: false,
    },
    {
        name: 'host',
        editable: false,
    },
    {
        name: 'httpPath',
        editable: true,
    },
    {
        name: 'httpPort',
        editable: true,
    },
    {
        name: 'lastConnect',
        editable: false,
    },
    {
        name: 'protocol',
        editable: false,
    },
    {
        name: 'sshPort',
        editable: true,
    },
    {
        name: 'targetHost',
        editable: false,
    },
    {
        name: 'targetPorts',
        editable: false,
    },
    {
        name: 'userAgent',
        editable: false,
    },
    {
        name: 'type',
        editable: true,
    },
    {
        name: 'version',
        editable: false,
    },
    {
        name: 'vncPort',
        editable: true,
    },
]

export const InvisibleProperties = [
    {
        name: 'hashedPassword',
    },
    {
        name: 'parent',
    },
    {
        name: 'port',
    },
    {
        name: 'server',
    },
    {
        name: 'online',
    },
    {
        name: 'category',
    },
    {
        name: 'site',
    },
]