<template>
  <b-card>
    <h5 >
      {{ label }}
    </h5>
      <div class="d-flex justify-content-end align-itmes-center mb-2">
        <b-button v-b-modal.inputModal class="m-1">{{
          $t("properties.usermenu.addMenuItem")
        }}</b-button>
        <b-button @click="addNodeRed" class="m-1">{{
          $t("properties.usermenu.addNodeRed")
        }}</b-button>
      </div>

    <div class="accordion" role="tablist">
      <b-card
        no-body
        class="mb-1"
        v-for="(path, index) in changedValue"
        :key="`accordion-${index}-${path.title}-${path.path}`"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            variant="white"
            class="text-left"
            v-b-toggle:[path.title]
            >{{ path.title }}
          </b-button>
        </b-card-header>
        <b-collapse :id="path.title" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="d-flex justify-content-end align-items-center">
              <b-button variant="outline-danger" @click="remove(path)"
                ><i class="fas fa-trash mr-2"></i
                >{{
                  $t("properties.usermenu.deleteName", { name: path.title })
                }}</b-button
              >
            </div>
            <b-form>
              <b-form-group
                :label="$t('properties.usermenu.title')"
                label-for="title"
                :description="$t('properties.usermenu.titleDescription')"
              >
                <b-form-input
                  :id="path.title + '-title'"
                  v-model="path.title"
                  :placeholder="$t('properties.usermenu.MyNewPath')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('properties.usermenu.Path')"
                label-for="path"
                :description="$t('properties.usermenu.pathDescription')"
              >
                <b-form-input
                  :id="path.title + '-path'"
                  v-model="path.path"
                  :placeholder="$t('properties.usermenu.my-new-path')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('properties.usermenu.imagePath')"
                label-for="img-path"
                :description="$t('properties.usermenu.imagePathDescription')"
              >
                <b-form-input
                  :id="path.title + '-img-path'"
                  v-model="path.imagePath"
                  placeholder="/bar.svg"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-card-body>
        </b-collapse>
      </b-card>
      <div class="buttons mt-2 d-flex justify-content-end align-items-center">
        <b-button variant="primary" class="m-1" @click="submitForm"
          ><i class="fas fa-save mr-2"></i
          >{{ $t("properties.usermenu.save") }}</b-button
        >
        <b-button class="m-1" @click="reset(value)"
          ><i class="fas fa-times mr-2"></i
          >{{ $t("properties.usermenu.cancel") }}</b-button
        >
      </div>
    </div>

    <text-input-modal
      modalId="inputModal"
      title="New menu item"
      label="Enter a title"
      @ok="addNewItem"
    />
  </b-card>
</template>

<script>
import TextInputModal from "@/components/TextInputModal.vue";
import { mapActions } from "vuex";

export default {
  name: "SubpathEditor",
  components: { TextInputModal },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      changedValue: JSON.parse(this.value),
    };
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    reset(value) {
      this.changedValue = JSON.parse(value);
    },
    submitForm() {
      this.$emit("ok", JSON.stringify(this.changedValue));
    },
    addNewItem(title) {
      if (!title) return;

      this.changedValue.push({
        title,
        path: "",
        imagePath: "",
      });
    },
    addNodeRed() {
      const nodeRedEntry = {
        title: "Node-Red",
        path: "/node-red/",
        imagePath: "/eos-node-red.svg",
      };
      if (this.changedValue.map((e) => e.path).includes(nodeRedEntry.path)) {
        this.fireAlert([this.$t('Warning'), this.$t('properties.usermenu.MenuAlreadyIncludesNodeRed'), 'warning']);
        return;
      }

      this.changedValue.push(nodeRedEntry);
    },
    remove(item) {
      this.changedValue = this.changedValue.filter((e) => {
        return e != item;
      });
    },
  },
  watch: {
    value(newValue) {
      // if value changes, update the model of the input field accordingly
      this.reset(newValue);
    },
  },
};
</script>

<style>
</style>