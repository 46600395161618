var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-overlay',{attrs:{"show":"","spinner-variant":"primary","no-wrap":""}})]},proxy:true}])},[_c('h1',[_vm._v(_vm._s(_vm.$t("properties.deviceProperties")))]),_c('div',{staticClass:"d-flex flex-lg-row flex-column"},[_c('b-card',{staticClass:"shadow mt-2"},[_c('h2',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-user-cog mr-2"}),_vm._v(_vm._s(_vm.$t("properties.baseProperties"))+" ")]),_vm._l((_vm.baseProperties),function(prop){return _c('div',{key:prop.name,staticClass:"property"},[_c('property-editor',{attrs:{"label":_vm.$t(("properties.names." + (prop.name))),"value":_vm.properties[prop.name],"description":_vm.$te(("properties.descriptions." + (prop.name)))
                ? _vm.$t(("properties.descriptions." + (prop.name)))
                : '',"disabled":!prop.editable},on:{"ok":function($event){return _vm.changeProperty(prop.name, $event)}}})],1)})],2),_c('div',{staticClass:"d-flex flex-column ml-lg-2"},[_c('b-card',{staticClass:"shadow mt-2"},[_c('h2',{staticClass:"\n              card-title\n              d-flex\n              justify-content-between\n              align-items-center\n            "},[_c('span',[_c('i',{staticClass:"fas fa-cogs mr-2"}),_vm._v(_vm._s(_vm.$t("properties.configuration")))])]),_vm._l((_vm.configurationProperties),function(prop){return _c('div',{key:prop.name,staticClass:"property"},[(prop.name == 'visible_subpaths')?_c('subpath-editor',{attrs:{"label":_vm.$t(("properties.names." + (prop.name))),"value":_vm.properties[prop.name],"description":_vm.$te(("properties.descriptions." + (prop.name)))
                  ? _vm.$t(("properties.descriptions." + (prop.name)))
                  : ''},on:{"ok":function($event){return _vm.changeProperty(prop.name, $event)}}}):(prop.name == 'update_configuration')?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$te(("properties.names." + (prop.name)))
                    ? _vm.$t(("properties.names." + (prop.name)))
                    : prop.name,"label-for":"update_configuration","invalid-feedback":_vm.$t('properties.updateConfigInvalid'),"state":_vm.updateConfigState,"description":_vm.updateConfigState
                    ? _vm.$t(("properties.descriptions." + (prop.name)))
                    : ''}},[_c('b-form-select',{attrs:{"options":_vm.updateConfigOptions,"id":"update_configuration","state":!_vm.updateConfigState?false:null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":undefined,"disabled":""}},[_vm._v("-- "+_vm._s(_vm.$t("properties.selectOption"))+" --")])]},proxy:true}],null,true),model:{value:(_vm.properties[prop.name]),callback:function ($$v) {_vm.$set(_vm.properties, prop.name, $$v)},expression:"properties[prop.name]"}})],1),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","disabled":!_vm.updateConfigState},on:{"click":function($event){return _vm.changeProperty(prop.name, _vm.properties[prop.name])}}},[_c('i',{staticClass:"fas fa-check"})])],1):_c('property-editor',{attrs:{"label":_vm.$te(("properties.names." + (prop.name)))
                  ? _vm.$t(("properties.names." + (prop.name)))
                  : prop,"value":_vm.properties[prop.name],"description":_vm.$te(("properties.descriptions." + (prop.name)))
                  ? _vm.$t(("properties.descriptions." + (prop.name)))
                  : ''},on:{"ok":function($event){return _vm.changeProperty(prop.name, $event)}}})],1)})],2),(_vm.userProperties)?_c('b-card',{staticClass:"shadow mt-2"},[_c('h2',{staticClass:"\n              card-title\n              d-flex\n              justify-content-between\n              align-items-center\n            "},[_c('span',[_c('i',{staticClass:"fas fa-user-cog mr-2"}),_vm._v(_vm._s(_vm.$t("properties.user-properties")))]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.userPropertyInput",modifiers:{"userPropertyInput":true}}]},[_vm._v(_vm._s(_vm.$t("properties.addUserProperty")))])],1),_c('b-alert',{attrs:{"show":Object.keys(_vm.userProperties).length == 0,"variant":"info"}},[_vm._v(_vm._s(_vm.$t("properties.usermenu.NoUserProperties")))]),_vm._l((Object.keys(_vm.userProperties)),function(prop){return _c('property-editor',{key:prop,attrs:{"label":_vm.$te(("properties.names." + prop))
                ? _vm.$t(("properties.names." + prop))
                : prop,"value":_vm.properties[prop],"description":_vm.$te(("properties.descriptions." + prop))
                ? _vm.$t(("properties.descriptions." + prop))
                : '',"showDelete":"","showCancel":false},on:{"ok":function($event){return _vm.changeProperty(prop, $event)},"delete":function($event){return _vm.deleteProperty(prop)}}})})],2):_vm._e()],1)],1),_c('b-card',{staticClass:"shadow mt-2"},[_c('h2',{staticClass:"card-title d-flex justify-content-between align-items-center"},[_c('span',[_c('i',{staticClass:"fas fa-terminal mr-2"}),_vm._v(_vm._s(_vm.$t("properties.system-properties")))]),_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-systemprops",modifiers:{"collapse-systemprops":true}}]},[_c('i',{staticClass:"fas fa-chevron-down"})])]),_c('b-collapse',{attrs:{"id":"collapse-systemprops"}},_vm._l((_vm.systemProperties),function(prop){return _c('property-editor',{key:prop.name,attrs:{"label":_vm.$t(("properties.names." + (prop.name))),"value":_vm.properties[prop.name],"description":_vm.$te(("properties.descriptions." + (prop.name)))
              ? _vm.$t(("properties.descriptions." + (prop.name)))
              : '',"disabled":!prop.editable},on:{"ok":function($event){return _vm.changeProperty(prop.name, $event)}}})}),1)],1),_c('b-card',{staticClass:"shadow mt-2"},[_c('h2',{staticClass:"card-title d-flex justify-content-between align-items-center"},[_c('span',[_c('i',{staticClass:"fas fa-user-shield mr-2"}),_vm._v(_vm._s(_vm.$t("properties.device-actions")))])]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.deleteDevice($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.deleteInformation')}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.validateId,"autocomplete":"off"},model:{value:(_vm.idValidation),callback:function ($$v) {_vm.idValidation=$$v},expression:"idValidation"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validateId}},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_vm._v(_vm._s(_vm.$t("device.invalidId", { id: this.$route.params.deviceid }))+" ")])],1),_c('p',[_vm._v(_vm._s(_vm.$t("device.deleteOfflineOnly")))]),_c('b-button',{attrs:{"variant":"danger","type":"submit","disabled":!_vm.validateId}},[_c('i',{staticClass:"fas fa-trash mr-2"}),_vm._v(_vm._s(_vm.$t("properties.deleteDevice")))])],1)],1),_c('text-input-modal',{attrs:{"modalId":"userPropertyInput","title":_vm.$t('properties.addUserProperty'),"label":_vm.$t('properties.enterTitle')},on:{"ok":_vm.addUserProperty}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }